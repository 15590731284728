import 'projects/digital-twin/src/scss/_variable.scss';
import { ICanvasComponent, IComponentMetaProperties, IDynamicComponentType, IEquipmentSegmentationSearchType, IEquipmentSegmentationType, IMetaPropertyType, ISceneObjReferences, ISceneObjType, ISiteAttachedAssetMetaProperties } from '../interfaces/constants.interface';
import { IComponentInputFieldTypes } from '../interfaces';
import { WorkflowRunStatus } from 'projects/digital-twin/src/app/shared/types';
/* #region constant functions */
const generateColorCodeVariable = (): any => {
  const styles = window.getComputedStyle(document.documentElement);
  const primaryDefault: string = styles.getPropertyValue('--primary-default').trim() || '#00F0FF';
  const dangerDefault: string = styles.getPropertyValue('--danger-default').trim() || '#FF4646';
  const cvWhite: string = styles.getPropertyValue('--cv-white').trim() || '#FFFFFF';
  const paleRred100: string = styles.getPropertyValue('--pale-red100').trim() || '#FAD8D8';
  const dullGreen100: string = styles.getPropertyValue('--dull-green100').trim() || '#3B855E';
  const softYellowGreen: string = styles.getPropertyValue('--soft-yellow-green').trim() || '#8FCF49';
  const dullGreen200: string = styles.getPropertyValue('--dull-green200').trim() || '#2E684A';
  const lightGrayishPurple: string = styles.getPropertyValue('--light-grayish-purple').trim() || '#A587CA';
  const brightCyan: string = styles.getPropertyValue('--bright-cyan').trim() || '#27ADAE';
  const vividRed: string = styles.getPropertyValue('--vivid-red').trim() || '#FF0000';
  const palePink: string = styles.getPropertyValue('--pale-pink').trim() || '#E9A3E5';
  const deepRed: string = styles.getPropertyValue('--deep-red').trim() || '#8E0C0C';
  const paleRed200: string = styles.getPropertyValue('--pale-red200').trim() || '#EFADAD';
  const vividYellow: string = styles.getPropertyValue('--vivid-yellow').trim() || '#FFF000';
  const lightBlue: string = styles.getPropertyValue('--light-blue').trim() || '#4F83D8';
  const gray100: string = styles.getPropertyValue('--gray100').trim() || '#666666';
  const vividPink: string = styles.getPropertyValue('--vivid-pink').trim() || '#FF33CC';
  return {
    primaryDefault, dangerDefault, cvWhite, paleRred100,
    dullGreen100, softYellowGreen, dullGreen200, lightGrayishPurple,
    brightCyan, vividRed, palePink, deepRed, paleRed200, vividYellow,
    lightBlue, gray100, vividPink
  };
};
/* #endregion constant functions */

/* #region constant varible initialization/declaration */
export const VERSION_SELECTOR = {
  SAVE_NEW_VERSION: "Save New Version",
  VIEW_VERSIONS: "View Versions",
  VERSIONS: "Versions"
}
export const COMPONENT_SIDEBAR = {
  MIN_WIDTH: 400,
  MAX_WIDTH: 600,
}
export const THUMBNAIL_2D_VIEW_EVENTS = {
  VERTICAL_VIEW: 'vertical-view',
  HORIZONTAL_VIEW: 'horizontal-view',
  EXPAND_VIEW: 'expand-view'
}
export const GLOBAL_COLOR_CODE_VARIABLES = {
  PRIMARY_DEFAULT: generateColorCodeVariable()?.primaryDefault || '#00F0FF',
  DANGER_DEFAULT: generateColorCodeVariable()?.dangerDefault || '#FF4646',
  CV_WHITE: generateColorCodeVariable()?.cvWhite || '#FFFFFF',
}
export const WORKFLOW_STEPS = {
  RESOURCE_INGESTION: "ResourceIngestion",
  NEXUS_MESH_GENERATION: "DigitalTwinOptimization",
  AI_TELCO_EQUIP_3D_DETECTION: "AITelcoEquip3DDetection",
  MANUAL_REVIEW_AI_TELCO_EQUIP_3D_DETECTION: "ManualReviewAITelcoEquip3DDetection",
  AI_3D_TO_2D_ANNOTATION: "AI3DTo2DAnnotation",
  MANUAL_REVIEW_AI_3D_TO_2D_ANNOTATION: "ManualReviewBeforeAMSPublish",
  AMS_PUBLISH: "AMSPublish",
}

export const WORKFLOW_STEP_TYPE = {
  MANUAL: 'manual',
  AUTO: 'auto',
}

export const WORKFLOW_RUN_STATUS: WorkflowRunStatus = {
  NOT_STARTED: "not_started",
  RUNNING: "running",
  SKIPPED: "skipped",
  COMPLETED: "completed",
  FAILED: "failed",
  ABORTED: "aborted",
  QUEUED: 'queued',
}
export const MISSION_STATUS = {
  COMPLETED: "COMPLETED",
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
}
export const ANNOTATION_PROCESS = {
  ANNOTATION: "annotate",
  CANCEL: "cancel",
  AFTER_CANCEL: "afterCancel",
  PUBLISH: "publish",
}
export const API_MESSAGE_EVENTS = {
  GENERIC_ERROR_MESSAGE: "Oops! Something went wrong. Please refresh the page and try again",
  GENERIC_CREATE_SUCCESS_MESSAGE: "Created Successfully!...",
  GENERIC_UPDATE_SUCCESS_MESSAGE: "Updated Successfully!...",
  GENERIC_DELETE_SUCCESS_MESSAGE: "Deleted Successfully!...",
  GENERIC_ERROR_MESSAGE_WRONG: "Oops! Something went wrong.No data found",
  GENERIC_ERR_MESSAGE: "Oops! Something went wrong",
  GENERIC_NETWORK_ERROR_MESSAGE: "Network error occurred",
  XML_REQUEST_FAILED_STATUS_MESSAGE: "Request failed with status",
  XML_TO_JSON_CONVERSION_ERROR_MESSAGE: "Error converting XML to JSON",
  GENERIC_DRAW_MESSAGE: "Polygon in coordinates must contain at least 4 sides",
  GENERIC_PERSISTENT: "The ability to select and identify individual components on this digital twin is not yet available",
  GENERIC_PERMISSION_DENIED_MESSAGE: "Permission denied!"
}
export const FORM_GROUP_CONSTANTS = {
  REQUIRED_FIELD_MESSAGE: "Please fill out this field"
}
export const CANVAS_GEOMETRIC_TYPE = {
  POINT_TYPE: 'Point',
  LINESTRING_TYPE: 'LineString',
  POLYGON_TYPE: 'Polygon'
}
export const CANVAS_PANELS = {
  COMPONENT_SEGMENTATION: 'component-segmentation',
  COMPONENT_ASSOCIATION: 'component-association',
  COMPONENT_ASSOCIATION_WITH_DISABLE_CONTROLS: 'component-association-with-disable-controls',
  COMPONENT_DETIALS: 'component-details',
  COMPONENT_FILTER: 'component-filter',
  COMPONENT_WORKFLOW_STEPS: 'component-workflow-steps',
  OFFSET_VALUE: 380,
  LEFT_PANEL_COLLAPSE_OFFSET_VALUE: 40,
  COMPONENT_VISUAL_GALLERY: 'visual-gallery'
}
export const CANVAS_VIEWER = {
  SCENE_BG: '#14171F',
  SCENE_FOG_COLOR: 0x050505,
  AMBIENT_LIGHT_COLOR: 0xffffff,
  CUBOID_DEFAULT_COLOR: '#FF33CC',
  CAMERA_SIZING_FACTOR: 100,
  CUBOID_BORDER_COLOR: 0xfff000,
  CUBOID_DEFAULT_SIZING: 1,
  CUBOID_DEFAULT_SEGEMENT: 1, //this value doesnt matter unless we are using custom shaders. Adversely impacts the performance. (note: the shattering happens on min value 1 to avoid balance value given)
  CUBOID_DEFAULT_MANUAL_NAME: 'ANT_',
  REFERENCE_MARKER_COLOR: 0xffff00,
  REFERENCE_POLYGON_COLOR: 0xffff00,
  MEASUREMENT_COLOR: 0x00F0FF,
  MEASUREMENT_COLOR_LABEL_TEXT_HEX: '#00F0FF',
  MEASUREMENT_COLOR_LABEL_BG_HEX: '#24262B',
  MEASUREMENT_COLOR_LABEL_BORDER_HEX: '#4E5B6D',
  REFERENCE_OFFSET: 0.05,
  BOUNDARY_WIDTH_DEFAULT: 1,
  BOUNDARY_WIDTH_SELECTED: 6,
}

export const COMPONENT_ASSOCIATION_STATUS_COMPARE = {
  CANCELLED: 'CANCELLED',
  DELETED: 'DELETED',
  AUDITED: 'AUDITED',
  REQUESTED: "REQUESTED",
  RESERVED: "RESERVED",
  OFFERED: "OFFERED",
  PROPOSED_CREATE: "PROPOSED CREATE",
  EXISTING: "EXISTING",
  PROPOSED_REMOVE: "PROPOSED REMOVE",
  PROPOSED_REMOVE_SWAP: "PROPOSED REMOVE - SWAP",
  REMOVED: "REMOVED",
  UNLICENSED: "UNLICENSED",
  UNCATEGORISED: "UNCATEGORISED",
  LEGACY_RESERVED: "LEGACY RESERVED",
  ORPHANED: "ORPHANED",
  UNMATCHED: "UNMATCHED"
}

export const COMPONENT_ASSOCIATION_SPECIAL_STATUS_LIST = [
  COMPONENT_ASSOCIATION_STATUS_COMPARE.PROPOSED_CREATE,
  COMPONENT_ASSOCIATION_STATUS_COMPARE.OFFERED,
  COMPONENT_ASSOCIATION_STATUS_COMPARE.RESERVED,
  COMPONENT_ASSOCIATION_STATUS_COMPARE.REQUESTED,
  COMPONENT_ASSOCIATION_STATUS_COMPARE.LEGACY_RESERVED,
];

export const ASSOCIATION_FILTER_ITEMS = {
  SHOW_ALL: 'Show All',
  SEGMENTED_ONLY: 'Segmented Only',
  TB_B: 'TBB',
  L1AD: 'L1AD',
  L1AV: 'L1AV'
}
export const IRESOURCE_TAG = {
  ORIGINAL_TAG: 'orig_full_res',
  THUMBNAIL_TAG: 'thumbnail',
  ANNOTATED_SNAPSHOT: 'annotated_snapshot',
  COMPRESSED_FULL_RES: 'compressed_full_res',
  LOS: 'line_of_sight',
  SV: 'top_to_down',
  TV: 'tower_view',
  IMAGE_GROUP_LOS: 'image_group:line_of_sight',
  IMAGE_GROUP_SV: 'image_group:top_to_down',
  IMAGE_GROUP_TV: 'image_group:tower_view',
  IMAGE_GROUP_FAV: 'image_group:from_above_view',
  IMAGE_GROUP_HIERARCHY: 'image_group_hierarchy:',
  PAGE_LIMIT: 50,
};
export const IRESOURCE_MULTI_TAG = {
  ORIGINAL_TAG: 'orig_full_res,line_of_sight',
  THUMBNAIL_TAG: 'thumbnail,line_of_sight'
};
export const IRESOURCE_TYPE = {
  IMAGE_RGB: 'imageRGB',
  MESH_OBJ: 'meshObj',
  MESH_OBJ_TILE: 'meshObjTile',
  CAMERA_PARAMS: 'cameraParams',
  MESH_NXZ: 'meshNxz',
  MESH_NXZ_TILE: 'meshNxzTile',
  MISC_FILE: 'miscFile'
};
export const THUMB_STAGE_VERITICAL_VIEW = {
  _PREFIX: "annotateVerticDto_",
  WIDTH: 340,
  HEIGHT: 180,
}
export const THUMB_STAGE_HORIZONTAL_VIEW = {
  _PREFIX: "annotateHorizDto_",
  WIDTH: 170,
  HEIGHT: 140,
}

export const ANNOTATION_DRAWBOX_INIT_PROPERTY = {
  DRAWMODE: "rectangle",
  DRAWBOX_NAME: "rect",
  POLY_NAME: "poly",
  POLY_NAME_TEMP: "polyTemp",
  DRAWBOX_X: 100,
  DRAWBOX_Y: 40,
  DRAWBOX_WIDTH: 425,
  DRAWBOX_HEIGHT: 450,
  DRAWBOX_FILL: "rgba(0, 240, 255, 0.2)",
  DRAWBOX_STROKE: "#00F0FF",
  DRAWBOX_STROKEWIDTH: 1,
  DRAWBOX_STROKEWIDTH_T2: 0.8,
  DRAWBOX_STROKEWIDTH_CLK: 10,
  DRAWBOX_STROKEWIDTH_CLK_7: 7,
  DRAWBOX_STROKEWIDTH_CLK_5: 5,
  DRAWBOX_STROKEWIDTH_CLK_2: 2,
  DRAWBOX_POLY_STROKEWIDTH_VIEW: 10,
  DRAWBOX_POLY_STROKEWIDTH_THUMB: 1,
  DRAWBOX_SHADOWBLUR: 1,
  DRAWBOX_OPACITY: 0,
  DRAWBOX_OPACITY_INIT: 1,
  DRAWBOX_FADE_IN_OPACITY: 1,
  DRAWBOX_FADE_IN_DURATION_T1: 1,
  DRAWBOX_FADE_IN_DURATION: 2,
  RADIUS: 40,
  CIRCLE_RED: '#00F0FF',
  CIRCLE_DRAW_PREFIX: 'circle_',
  PROGRESSIVE_TRANSDURATION: 1000,
  PROGRESSIVE_FRAMERATE: 10,
  PROGRESSIVE_FRAME: 0,
  PROGRESSIVE_BLURRADIUS: 50,
  PROGRESSIVE_PERCENTAGE: 100,
  PROGRESSIVE_BLUR_RADIUS_L1: 0,
  PROGRESSIVE_BLUR_RADIUS_L2: 2,
  PROGRESSIVE_BLUR_RADIUS_L3: 50,
  PROGRESSIVE_BLUR_STAGE_WID: 540,
  PROGRESSIVE_BLUR_AJST_HEIGHT: 30
}
export const STAGE_2D_CANVAS_COMMON_VIEW = {
  THUMB_STAGENAME: "thumbnailStage",
  ANNOTATION_TYPE: "annotation_2d",
  ANNOTATION_TAG: "annotation",
  VALIDATE_TAG: "validated",
  INVALIDATE_TAG: "invalidate",
  PAGE_LIMIT: 10,
  RANGE_VALUE: 50,
  ZOOM_IN_OUT_SCALE_BY: 1.1,
  ZOOM_IN_SCALE_BY: 1.2,
  ZOOM_IN_SCALE_BY_T1: 0.1,
  ZOOM_OUT_SCALE_BY: 0.9,
  ZOOM_LEVEL_FACTOR: 1,
  ZOOM_LEVEL_INIT: 1,
  BG_FADE_IN_OPACITY: 1,
  BG_FADE_IN_DURATION_T1: 1,
  BG_FADE_IN_DURATION: 2,
  CONFIRM_BOX_ALERT: 'Are you sure you want to remove the box!',
  IMAGE_PAGE_LIMIT: 200,
  IMAGE_PAGE_MAX_LIMIT: 500,
  IMAGE_INDEX_KEY: 1,
  ANNOTATION_PAYLOAD_ADD: "add",
  ANNOTATION_PAYLOAD_UPDATE: "update",
  ANNOTATION_PAYLOAD_REMOVE: "remove",
  CANVAS_MINIMIZE: "minimize",
  CANVAS_CONTAINER: "canvas-container",
  CANVAS_MSG_TITLE: "Annotation canvas",
  CANVAS_VALIDATE_MSG: "Annotation canvas validated",
  CANVAS_COLLECTIONID: "grid-gt-annotations",
  CANVAS_COLLECTION_TELCO: "telco-equip-annotations",
  DISK_INSULATOR: "disk_insulator",
  BROKEN_INSULATOR: "broken_insulator",
  ARCHING_HORN: "arching_horn_misaligned",
  ANTENNA: "antenna",
  TRANSCEIVER: "Transceiver",
  HEAD_FRAME_MOUNT: "Head frame Mount",
  AVIATION_LIGHT: "Aviation Light",
  SHELTER: "Shelter",
  CURSOR_POINTER: "pointer",
  CURSOR_MOVE: "move",
  CURSOR_DEFAULT: "default",
  CURSOR_CROSS: "crosshair",
  CLIENT_RECT_DIVIDER: 2,
  CLIENT_RECT_DEGREE: 180,
  DRAW_RECT: "Rect",
  DRAW_LINE: "Line",
  IMAGE_LOADING: "Canvas image is loading..",
  HORZ_RECT_THUMB_ADJUST: 2,
  VIEWER_LOADER_BLOCK: "block",
  VIEWER_LOADER_NONE: "none",
  IMAGE_PER_CHUNK: 5,
  ANNOTATED_IMAGE_PER_CHUNK: 10,
  POLYGON_REQUIRED_LENGTH: 6,
  SET_BGIMAGE_ZOOM_OUT: 0.1,
  DEL_CONFIRM_CONTENT:'Are you sure you want to delete this ?',
  DEL_CONFIRM_CONTENT_SNAP:'Are you sure you want to delete this snapshot image?',
  DEL_CONFIRM_TITLE:'Delete Confirmation',
  DEL_CONFIRM_DEL_BTN:'Delete',
  DEL_CONFIRM_CANCEL_BTN:'Cancel',
  TAG_VERTICAL_VIEW: 'vertical',
  TAG_HORIZONTAL_VIEW: 'horizontal',
  GROUP_TAG_NAME: 'displayTag',
  CANVAS_VIEWER_REF:"viewer_canvas_",
  CONFIRM_BUTTON_TEXT: 'Confirm',
  CANCEL_BUTTON_TEXT: 'Cancel',
  AMS_PUBLISH_TITLE: 'AMS Publish',
  AMS_PUBLISH_CONTENT: 'Are you sure you want to publish this?',
}
export const CANVAS_INPUT_CHANGE_PROPERTY = {
  DATA_COORIDINATES: "dataCooridnates",
  SELECTED_ITEM: "selectedItem",
  THUMBNAIL_VIEW: "thumbnailView",
  CANVAS_QUERY: "canvasQueryParamsObject",
  SETBACKGROUND_SRC: "setBackgroundImageSrc",
}
export const CANVAS_ANNOTATION_CONTROLLER_TYPES = {
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale',
}
export const CANVAS_MODES = {
  EQUIPMENT_REFERENCE_POINT: 'equipmentReferencePoint',
  MODEL_REFERENCE_POINT: 'referencePointModel',
  EQUIPMENT_REFERENCE_VECTOR: 'referenceVector',
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale',
  ANNOTATION: 'annotation',
  POLYGON: 'polygon',
  MEASUREMENT: 'measurement',
  SHOW_SUB_ICONS: 'showSubIcons',
  DEFAULT: "NONE",
  NONE: "NONE",
}
export const CANVAS_NOTIFY_MESSAGES = {
  NO_COMPONENT_FOUND: 'Oops!, selected component not found.',
  SELECT_NO_OBJECT_FOUND: "Please select scene object to associate!...",
  ANNOTATION_NOT_FOUND: "Annoataion image not found",
  NO_SCENE_OBJECT_FOUND: 'Scene object not found!...',
  NO_SCENE_OBJECT_SELECTED: 'Please select scene object to associate!...',
  CAMERA_PARAMETER_NOT_FOUND: 'Oops!, camera parameter not found.',
  DUPLICATE_ERROR_NOT_ALLOWED: 'Please mark a new bouding box to duplicate',
  DUPLICATE_ERROR_NO_TYPE: 'Oops!, type of the component not found',
  DUPLICATE_ERROR_NO_COMPONENT: 'Oops!, component not found for duplication',
  DUPLICATE_SUCCESS_COMPONENT: 'Duplicated current bouding box',
  DATA_RESOURCE_NOT_FOUND: 'No Model found for this project/site/asset'
}
export const SCENE_DEF_DOC_SKELETON_DATA = {
  "name": "",
  "projectId": "",
  "missionId": "",
  "siteId": "",
  "globalCrs": null,
  "propertyDefinitions": [],
  "sceneObjects": [],
  "sceneDetails": {
    "gravityVector": null,
    "groundPlane": null
  },
  "tags": []
}
const STATIC_COMPONENT_TYPE = {
  COMPONENT: 'component',
  SHARED_COMPONENT: 'shared-component',
}
const EQUIPMENT_SEGMENTATION_TYPE: IEquipmentSegmentationType = {
  antenna: 'Antennas',
  transceiver_junction: 'Transceiver Junctions',
  head_frame_mount: 'Mounts',
  shelter: 'Shelters',
  aviation_light: 'Aviation Lights',
  tower_landmark: 'Tower Landmarks',
  measurement: 'Measurements'
}
const EQUIPMENT_SEGMENTATION_SEARCH_TYPE: IEquipmentSegmentationSearchType = {
  ANTENNA: 'ANT',
  TRANSCEIVER_JUNCTION: 'TJD',
  HEAD_FRAME_MOUNT: 'HFM',
  SHELTER: 'SHL',
}
const SCENE_OBJ_TYPE: ISceneObjType = {
  POINT: 'point',
  POLYGON: 'polygon'
}
const DYNAMIC_COMPONENT_TYPE: IDynamicComponentType = {
  PORT: 'Port',
  PAINT: 'Paint',
  FEEDER_TAILS_CABLE_TRAYS: 'FeederTails-CableTrays',
  TRANSCEIVER: 'Transceiver',
  HEAD_FRAME: 'Headframe',
  ANTENNA: 'Antenna',
  SHELTER: 'Shelter',
  AVIATION_LIGHT: 'AviationLight'
}
export const COMPONENT_DETAILS_META_PROPERTIES: IComponentMetaProperties = {
  mountingHeightM: { actualKey: 'mountingHeightM', displayName: 'Mounting Height (m)' },
  lengthMM: { actualKey: 'lengthMM', displayName: 'Length (mm)' },
  lengthM: { actualKey: 'lengthM', displayName: 'Length (m)' },
  widthMM: { actualKey: 'widthMM', displayName: 'Width (mm)' },
  depthMM: { actualKey: 'depthMM', displayName: 'Depth (mm)' },
  weightKG: { actualKey: 'weightKG', displayName: 'Weight (Kg)' },
  lowestFrequencyMHZ: { actualKey: 'lowestFrequencyMHZ', displayName: 'Lowest Frequency (MHz)' },
  highestFrequencyMHZ: { actualKey: 'highestFrequencyMHZ', displayName: 'Heighest Frequency (MHz)' },
  locationOrientationDesignDEG: { actualKey: 'locationOrientationDesignDEG', displayName: 'Location Orientation Design (deg)' },
  verticalSeparationRequiredMM: { actualKey: 'verticalSeparationRequiredMM', displayName: 'Vertical Seperation Required (mm)' },
  horizontalSeparationRequiredMM: { actualKey: 'horizontalSeparationRequiredMM', displayName: 'Horizontal Separation Required (mm)' },
  anetureSizeMM: { actualKey: 'anetureSizeMM', displayName: 'Aneture Size (mm)' },
  midPointHeightM: { actualKey: 'midPointHeightM', displayName: 'Mid Point Height (m)' },
  xdesignM: { actualKey: 'XdesignM', displayName: 'X Design (m)' },
  ydesignM: { actualKey: 'YdesignM', displayName: 'Y Design (m)' },
  zdesignM: { actualKey: 'ZdesignM', displayName: 'Z Design (m)' },
  directionOrientationDesignDEG: { actualKey: 'directionOrientationDesignDEG', displayName: 'Direction Orientation Design (deg)' },
  legacyAssetID: { actualKey: 'legacyAssetID', displayName: 'Legacy Asset ID' },
  antHPlaneBeamWidthDEG: { actualKey: 'antHPlaneBeamWidthDEG', displayName: 'Ant H Plane Beam Width (deg)' },
  antEPlaneBeamWidthDEG: { actualKey: 'antEPlaneBeamWidthDEG', displayName: 'Ant E Plane Beam Width (deg)' },
  apertureSizeMM: { actualKey: 'apertureSizeMM', displayName: 'Aperture Size (mm)' },
  createdByUserID: { actualKey: 'createdByUserID', displayName: 'Created By User Id' },
  startHeightM: { actualKey: 'startHeightM', displayName: 'Start Height (m)' },
  endHeightM: { actualKey: 'endHeightM', displayName: 'End Height (m)' },
  minimumFrequencyMHz: { actualKey: 'minimumFrequencyMHz', displayName: 'Minimum Frequency (MHz)' },
  maximumFrequencyMHz: { actualKey: 'maximumFrequencyMHz', displayName: 'Maximum Frequency (MHz)' },
  headframeESAM2: { actualKey: 'headframeESAM2', displayName: 'Head Frame ESA (m²)' },
  frameLengthM: { actualKey: 'frameLengthM', displayName: 'Frame Length (m)' },
  radialArmLengthM: { actualKey: 'radialArmLengthM', displayName: 'Radial Arm Length (m)' },
  frameHeightM: { actualKey: 'frameHeightM', displayName: 'Frame Height (m)' },
  frameExtensionLengthM: { actualKey: 'frameExtensionLengthM', displayName: 'Frame Extension Length (m)' },
  mountingBarLengthM: { actualKey: 'mountingBarLengthM', displayName: 'Mounting Bar Length (m)' },
  installedHeightM: { actualKey: 'installedHeightM', displayName: 'Installed Height (m)' },
  headFrameMountTypeCode: { actualKey: 'headFrame-MountTypeCode', displayName: 'Head Frame - Mount Type Code' },
  antennaClassCode: { actualKey: 'antennaClassCode', displayName: 'Antenna Class Code' },
  manufacturer: { actualKey: 'manufacturer', displayName: 'Manufacturer' },
  modelNumber: { actualKey: 'modelNumber', displayName: 'Model Number' },
  mountClassCode: { actualKey: 'mountClassCode', displayName: 'Head Frame Mount Class Code' },
  junctionDeviceClassCode: { actualKey: 'junctionDeviceClassCode', displayName: 'Transceiver Junction Device Code' },
  orientation: { actualKey: 'orientation', displayName: 'Orientation' },
  ownerCompany: { actualKey: 'ownerCompany', displayName: 'Owner Company' },
  siteReferenceId: { actualKey: 'siteReferenceId', displayName: 'Site Reference ID' },
  associatedTransceivers: { actualKey: 'associatedTransceivers', displayName: 'Associated Transceivers' },
  latitude: { actualKey: 'latitude', displayName: 'Latitude' },
  longitude: { actualKey: 'longitude', displayName: 'Longitude' },
  mountingHeight: { actualKey: 'mountingHeight', displayName: 'Mounting Height' },
  locationOrientationActualDEG: { actualKey: 'locationOrientationActualDEG', displayName: 'Location Orientation Actual (deg)' },
  directionOrientationActualDEG: { actualKey: 'directionOrientationActualDEG', displayName: 'Direction Orientation Actual (deg)' },
  xactualM: { actualKey: 'XactualM', displayName: 'X Actual (m)' },
  yactualM: { actualKey: 'YactualM', displayName: 'Y Actual (m)' },
  zactualM: { actualKey: 'ZactualM', displayName: 'Z Actual (m)' },
  junctionDeviceTypeCode: { actualKey: 'junctionDeviceTypeCode', displayName: 'Transceiver Junction Device Type Code' },
  junctionDeviceLocation: { actualKey: 'junctionDeviceLocation', displayName: 'Transceiver Junction Device Location' },
  junctionDeviceCategory: { actualKey: 'junctionDeviceCategory', displayName: 'Transceiver Junction Device Category' },
  dcDcConvertor: { actualKey: 'dcDcConvertor', displayName: 'DC/DC Convertor' },
  mountName: { actualKey: 'mountName', displayName: 'Head Frame Mount Name' },
  mountCategory: { actualKey: 'mountCategory', displayName: 'Head Frame Mount Category' },
  surfaceAreaM2: { actualKey: 'surfaceAreaM2', displayName: 'Surface Area (m²)' },
  maxPowerW: { actualKey: 'maxPowerW', displayName: 'Max Power (W)' },
  assetReferenceId: { actualKey: 'assetReferenceId', displayName: 'Asset Reference ID' },
  antennaType: { actualKey: 'antennaType' , displayName: 'Antenna Type' },
  equipmentType: { actualKey: 'equipmentType', displayName: 'Equipment Type' },
  lensType: { actualKey: 'lensType', displayName: 'Lens Type' },
}
const SCENE_OBJ_REFERENCES: ISceneObjReferences = {
  REF_POINT: { TYPE: "point", NAME: "Reference Point" },
  REF_POLYGON: { TYPE: "polygon", NAME: "Reference Polygon" },
}
export const CANVAS_COMPONENT: ICanvasComponent = {
  SCENEOBJ_CHILD_PROP_VALUE: 59,
  SCENEOBJ_COMPONENT_REFERENCE_TYPE: 'TOWER_LANDMARK',
  SCENEOBJ_PROPERTY_TYPE_TOWER_BASE_CENTER: 'tower_base_center',
  SCENEOBJ_PROPERTY_TYPE_TOWER_POLYGON: 'tower_structure_orientation_polygon',
  SCENEOBJ_PROPERTY_TYPE_EQUPIMENT_REFERENCE_POINT: 'telco_equipment_reference_point',
  SCENEOBJ_PROPERTY_TYPE_EQUPIMENT_REFERENCE_VECTOR: 'telco_equipment_reference_vector',
  SCENEOBJ_PROPERTY_CONFIDENCE: 'confidence',
  SCENEOBJ_PROPERTY_DIM_ORDER: 'dims_order',
  SCENEOBJ_PROPERTY_ANN_REF: 'annotation_2d_references',
  CONFIDENCE_ROUNDER: 100,
  IMAGE_PER_CHUNK: 10,
  STATIC_COMPONENT_TYPE: STATIC_COMPONENT_TYPE,
  DYNAMIC_COMPONENT_TYPE: DYNAMIC_COMPONENT_TYPE,
  EQUIPMENT_SEGMENTATION_TYPE: EQUIPMENT_SEGMENTATION_TYPE,
  EQUIPMENT_SEGMENTATION_SEARCH_TYPE: EQUIPMENT_SEGMENTATION_SEARCH_TYPE,
  SCENE_OBJ_TYPE: SCENE_OBJ_TYPE,
  CALLER_ID: 'frontend',
  COMPONENT_DETAILS_META_PROPERTIES: COMPONENT_DETAILS_META_PROPERTIES,
  SCENE_OBJ_REFERENCES: SCENE_OBJ_REFERENCES,
  DEFAULT_COLOR: '#666666'
}
export const CANVAS_INTERVAL = {
  WORKFLOW_RUN_INTERVAL_TIME: 180000,
  WORKFLOW_RUN_TIMEOUT_TIME: 5000
}
export const CANVAS_ZOOM_TYPE = {
  ZOOM_IN: 'zoomIn',
  ZOOM_OUT: 'zoomOut'
}
export const NAMING_CONVENTIONS = {
  CAMEL_CASE: "camelCase"
}
export const PROPERTY_LIST = [
  { _id: "dims_order", propertyName: "Dimensions Ordering", propertyDescription: "Defines the order to assign width, depth and height of cuboid" },
  { _id: "telco_equipment_reference_vector", propertyName: "Telco Equipment Reference Direction", propertyDescription: "Unit vector representing telco equipment orientation" },
  { _id: "telco_equipment_reference_point", propertyName: "Telco Equipment Reference Point", propertyDescription: "A reference point on telco equipment cuboid" },
  { _id: "tower_base_center", propertyName: "Telco Tower Base Center", propertyDescription: "A reference point on telco tower base center" },
  { _id: "tower_structure_orientation_polygon", propertyName: "Telco Tower Landmark", propertyDescription: "Defines telco tower landmark" },
  { _id: "confidence", propertyName: "AI Confidence", propertyDescription: "The confidence attached to a scene object by AI" },
  { _id: "annotation_2d_references", propertyName: "Annotated Snapshots", propertyDescription: "Reference to the Ids a snapshot image data resources" },
];

export const COMPONENT_PARAMS = {
  PAGE_LIMIT: 200
}
export const Equipment_Type = {
  ANTENNA: "ANT",
  TOWER_JUNCTION_DEVICE: "TJD"
}

export const COMPONENT_EQUIPMENT_TYPES = {
  ANTENNA: 'antenna',
  TRANSCEIVER_JUNCTION: 'transceiver_junction',
  HEAD_FRAME_MOUNT: 'head_frame_mount',
  SHELTER: 'shelter',
  AVIATION_LIGHT: 'aviation_light',
  MEASUREMENT: 'measurement'
}

export const COMPONENT_GROUP_TYPES = {
  "ANTENNA": { type: "antenna", name_prefix: 'ANT' },
  "TRANSCEIVER_JUNCTION": { type: "transceiver_junction", name_prefix: 'TJD' },
  "HEAD_FRAME_MOUNT": { type: "head_frame_mount", name_prefix: 'HFM' },
  "SHELTER": { type: "shelter", name_prefix: 'SHL' },
  "AVIATION_LIGHT": { type: "aviation_light", name_prefix: 'AVL' },
} as const
export const COMPONENT_GROUP_TYPE_LIST = [
  COMPONENT_GROUP_TYPES.ANTENNA,
  COMPONENT_GROUP_TYPES.AVIATION_LIGHT,
  COMPONENT_GROUP_TYPES.HEAD_FRAME_MOUNT,
  COMPONENT_GROUP_TYPES.SHELTER,
  COMPONENT_GROUP_TYPES.TRANSCEIVER_JUNCTION
]
export const VIEW_HELPER_STYLE = {
  ZINDEX: 1,
  POSITION: "absolute",
  TOP: "5em",
  EXPANDED_LEFT: "37em",
  COLLAPSED_LEFT: "8em",
  CURSOR: "pointer",
  BGCOLOR: "rgb(255 255 255 / 6%)",
  BORDER_RADIUS: "100%"
}
export const DEFAULT_KEYS = {
  NONE: '-- None --'
}
export const ORDER_TYPE = {
  ASCENDING: "Ascending",
  DESCENDING: "Descending"
}
export const RESET_OBSERVABLE_METHOD = {
  DATA_RESOURCE: 'dataResource'
}
export const SITE_ATTACHED_ASSET_META_PROPERTIES: ISiteAttachedAssetMetaProperties = {
  heightM: { actualKey: 'heightM', displayName: 'Height (m)' },
  heightExtensionM: { actualKey: 'heightExtensionM', displayName: 'Height Extension (m)' },
  orientationDesignDEG: { actualKey: 'orientationDesignDEG', displayName: 'Orientation Design (deg)' },
  orientationActualDEG: { actualKey: 'orientationActualDEG', displayName: 'Orientation Actual (deg)' },
  structureRLM: { actualKey: 'structureRLM', displayName: 'Structure (rlm)' },
  siteHeightRLM: { actualKey: 'siteHeightRLM', displayName: 'Site Height (rlm)' }
}
export const META_PROPERTY_TYPE: IMetaPropertyType = {
  ATTACHED_ASSET: 'attachedAsset',
  COMPONENT: 'component',
}

export const UNDO_COMPONENTS = {
  CANVAS_VIEWER: 'canvas_viewer',
}
export const UNDO_COMPONENTS_TYPE = {
  MARKER_ADD: 'marker_add',
  MARKER_DELETE: 'marker_delete',
  MARKER_DRAGGED: 'marker_dragged',
  MARKER_ROTATE: 'marker_rotate',
  MARKER_SCALE: 'marker_scale',
  MEASUREMENT_POINT_ADD: 'measurement_point_add',
  MEASUREMENT_POINT_CLEAR: 'measurement_point_clear',
  MEASUREMENT_POINT_CHANGE: 'measurement_point_change',
}
export const MARGIN_VALUE = {
  SHOW_BY_ICON: "22px",
  SHOW_BY_TAB: "0px"
}
export const HEIGHT_VALUE = {
  SHOW_BY_ICON: "calc(100% - 176px)",
  SHOW_BY_TAB: "calc(100% - 140px)"
}
export const SHORTCUT_KEYS = {
  ESCAPE: "escape",
  F8: "f8",
  F9: "f9",
  F10: "f10",
  ctrlG: "g",
  ctrlZ: "z",
  DELETE: "delete",
}

export const COMPONENT_INFO_MODAL = {
  name: 'Unknown',
  x: '0' ,
  y: '0',
  z: '0',
  length: '0',
  width: '0',
  depth: '0',
  azimuth: '0'
}

export const DELETE_CONFIRMATION_CONFIG = {
  title: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_TITLE,
  message: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_CONTENT,
  confirmBtnText: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_DEL_BTN,
  cancelBtnText: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_CANCEL_BTN
}

export const AMS_PUBLISH_CONFIG = {
  title: STAGE_2D_CANVAS_COMMON_VIEW.AMS_PUBLISH_TITLE,
  message: STAGE_2D_CANVAS_COMMON_VIEW.AMS_PUBLISH_CONTENT,
  confirmBtnText: STAGE_2D_CANVAS_COMMON_VIEW.CONFIRM_BUTTON_TEXT,
  cancelBtnText: STAGE_2D_CANVAS_COMMON_VIEW.CANCEL_BUTTON_TEXT
}

export const COMPONENT_INPUT_FIELD_TYPES: IComponentInputFieldTypes = {
  string: 'text',
  number: 'number'
}

export const TEMPLATE_MODULE_TYPE = {
  COMPONENT: 'component',
}

export const CREATE_DEFECT_PROPERTIES = {
  REFERENCE_POINT:"Reference Point",
  REFERENCE_DIRECTION: "Reference Direction",
  AI_CONFIDENCE:"AI Confidence",
  DIMENSION_ORDERING:" Dimension Ordering",
  ANNOTATED_SNAPSHOTS:"Annotated Snapshots"
}

export const DISPLAY_NAME = "displayName"

export const CANVAS_COMPONENT_LABELS = {
  MEASUREMENT: "Measurements",
  TOWER_LANDMARK: "Tower Landmarks",
  PROPOSED: "Proposed",
}

export const BEST_VIEW_PARAMS = {
  ANGLE_THRESHOLD: 22,
  DISTANCE_THRESHOLD: 20,
  NUM_OF_IMAGES: 5
}

export const SEARCH_COMPONENT_PROPERTIES = [
  'accHeading', 
  'accLabel', 
  'accStatus', 
  'accMetaData.assetReferenceId',
  'accMetaData.associatedStructure',
  'accMetaData.ownerCompany',
  'accMetaData.siteReferenceId',
  'accMetaData.junctionDeviceCategory',
  'accMetaData.junctionDeviceClassCode',
  'accMetaData.junctionDeviceLocation',
  'accMetaData.manufacturer',
  'accMetaData.modelNumber',
  'accMetaData.junctionDeviceCategory',
  'accMetaData.antennaClassCode',
]

export const AXIS = ['x', 'y', 'z']